import React from 'react'
import Taste from '@/components/Taste'
import RoundedLink from '@/components/RoundedLink'

export interface TasteDate {
  tasteData?: any
  buttonText?: string
  ButtonLink?: string
  heading?: string
  alignLeft?: boolean
}

const TastesShowCase = ({ tasteData, heading, alignLeft, buttonText, ButtonLink }: TasteDate) => {
  if (!tasteData) {
    return null
  }

  return (
    <div className={`my-14`}>
      {heading && (
        <div className="flex justify-center items-center mt-4 text-center">
          <h2 className="text-primary mb-8">{heading}</h2>
        </div>
      )}
      <div className={`flex flex-wrap ${alignLeft ? '' : 'justify-center'}`}>
        {tasteData.map((item: any) => {
          return (
            <Taste
              frontpage={true}
              heading={item.title}
              link={item.uri}
              focus={item?.focus}
              img={item?.featuredImage?.node?.localFile}
              key={item.id}
              alt={item?.featuredImage?.node?.altText}
            />
          )
        })}
      </div>
      <div className="flex justify-center items-center mt-4">
        {ButtonLink && (
          <RoundedLink className="" to={ButtonLink}>
            {buttonText}
          </RoundedLink>
        )}
      </div>
    </div>
  )
}

export default TastesShowCase
