import React from 'react'
import Layout from '@/components/Layout'
import TextAndImage from '@/components/TextAndImage'
import { graphql } from 'gatsby'
import Outro from '@/components/Outro'
import Container from '@/components/Container'
import CloudContainer from '@/components/CloudContainer'
import TastesShowCase from '@/components/TastesShowCase'
import { tasteData, seo, wp, pagelang } from '@/types.d'
import Onview from '@/components/Onview'

interface HomeTemplateData {
  data: {
    page: {
      title: string
      uri: string
      outroHeading: string
      outroButton: string
      secondaryButton: string
      outroText: string
      secondaryHeading: string
      secondaryText: string
      secondaryShowCloud: boolean
      frontpageSecondaryImage: {
        altText: string
        localFile: any
      }
      mainContent: any
      frontPageMainImage: any
      mainButton: string
      tastes: tasteData
      tastesHeading: string
      featuredImage: any
      language: pagelang
      translations: any
      seo: seo
    }
    mainMenu: any
    wp: wp
    instagram: any
    form: any
  }
  pageContext: any
}

const HomeTemplate = ({ data }: HomeTemplateData) => {
  const { page, mainMenu, wp, instagram, form } = data
  const langData = { languages: wp.languages, currentLang: page.language.slug, translations: page.translations }

  const mainbutton = page?.mainButton ? JSON.parse(page?.mainButton) : ''
  const outroButton = page?.outroButton ? JSON.parse(page?.outroButton) : ''
  const secondaryButton = page?.secondaryButton ? JSON.parse(page?.secondaryButton) : ''

  mainMenu.navButton = wp?.navButtons || ''
  const tastesPage =
    page.language.slug === 'fi'
      ? wp.archiveButton.archiveFi
      : page.language.slug === 'en'
      ? wp.archiveButton.archiveEn
      : wp.archiveButton.archiveFi

  const tastebutton = tastesPage ? JSON.parse(tastesPage) : ''

  return (
    <Layout
      form={form}
      featuredImage={page?.featuredImage}
      nav={mainMenu}
      lang={langData}
      instagram={instagram}
      isFrontpage={true}
      uri={page?.uri}
      seodesc={page?.seo?.metaDesc}
      seokeywors={page?.seo?.metaKeywords}
      seotitle={page?.seo?.title}
    >
      <Container className="my-20 md:my-24">
        <Onview>
          <TextAndImage
            heading={page?.title}
            alt={page?.frontPageMainImage?.altText}
            link={mainbutton?.url}
            linkText={mainbutton?.anchor}
            img={page?.frontPageMainImage}
            imgSide="1"
          >
            {page?.mainContent}
          </TextAndImage>
        </Onview>
      </Container>
      {page?.tastes && (
        <Container className="mt-20 md:mt-20 mb-18">
          <Onview>
            <TastesShowCase
              tasteData={page?.tastes}
              heading={page?.tastesHeading}
              buttonText={tastebutton?.anchor}
              ButtonLink={tastebutton?.url}
            />
          </Onview>
        </Container>
      )}
      {page?.secondaryText && (
        <CloudContainer
          className="mt-18 mb-18"
          block="front-page-text-image"
          color={page?.secondaryShowCloud ? 'mint' : 'hide'}
        >
          <Onview>
            <TextAndImage
              heading={page?.secondaryHeading}
              alt={page?.frontpageSecondaryImage?.altText}
              link={secondaryButton?.url}
              linkText={secondaryButton?.anchor}
              img={page?.frontpageSecondaryImage}
              imgSide="2"
            >
              {page?.secondaryText}
            </TextAndImage>
          </Onview>
        </CloudContainer>
      )}
      {page?.outroHeading && (
        <Container className="mt-18 mb-20 md:mb-28">
          <Onview>
            <Outro heading={page?.outroHeading} ButtonText={outroButton?.anchor} buttonUrl={outroButton?.url}>
              {page?.outroText}
            </Outro>
          </Onview>
        </Container>
      )}
    </Layout>
  )
}

export const HomePageQuery = graphql`
  query HomePageById($id: String!, $menu: [WpMenuLocationEnum], $translang: String, $footerForm: Int) {
    page: wpPage(id: { eq: $id }) {
      ...featuredImageFrontPage
      ...frontPageFields
      ...langdata
      ...seo
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
      ...subscribe
      ...archivePage
    }
    instagram: allInstagramContent(limit: 5) {
      ...instagram
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $footerForm }) {
      ...GravityFormFields
    }
  }
`

export default HomeTemplate
