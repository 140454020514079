import React from 'react'
import RoundedLink from '@/components/RoundedLink'
import Logo from '@/assets/Logo'

export interface Outro {
  heading?: string
  children?: React.ReactNode
  className?: string
  buttonUrl?: string
  ButtonText?: string
}

const Outro = ({ heading, children, className, buttonUrl, ButtonText }: Outro) => {
  return (
    <div className={`w-full my-20 ${className}`}>
      <div className="text-center text-primary flex flex-col items-center">
        <div className="w-40 outro-logo grow-0 shrink-0">
          <Logo />
        </div>
        {heading && <h2 className="mt-6">{heading}</h2>}
        {children && <p className="font-bold mt-3 text-big">{children}</p>}
        {buttonUrl && (
          <RoundedLink className="text-white bg-primary mt-10" to={buttonUrl || '/'}>
            {ButtonText}
          </RoundedLink>
        )}
      </div>
    </div>
  )
}

export default Outro
